// Detection des supports tactiles
var supports = (function() {
    var d = document.documentElement,
        c = "ontouchstart" in window || navigator.msMaxTouchPoints;
    if (c) {
        
        // ON EST SUR MOBILE

    } else { 
        
        // ON EST SUR PC

    }
})();
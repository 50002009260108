
// Gestion des videos html5
/*$(".accueil_entete_case").mouseover(function() {
	$(this).children().children().trigger('play');
});

$(".accueil_entete_case").mouseout(function() {
	$(this).children().children().trigger('pause');
});*/


// Gestion des menus côtés
$("#bouton_galerie_photo").click(function() {
	$('#accueil_entete_galerie').addClass('opened');
});

$("#close_galerie_photo").click(function() {
	$('#accueil_entete_galerie').removeClass('opened');
});

$("#close_video").click(function() {
  $('#lightbox_video').modal('hide');
  $('#lightbox_video video').modal('hide');
  var $frame = $('iframe#vimeo_iframe');
  // saves the current iframe source
  var vidsrc = $frame.attr('src');
  // sets the source to nothing, stopping the video
  $frame.attr('src',''); 
  // sets it back to the correct link so that it reloads immediately on the next window open
  $frame.attr('src', vidsrc);
});


// Fermeture de la nav quand on clic sur un lien avec anchor
$(".nav a").click(function() {
	$('.collapse').collapse('hide');
});

// Ouverture du bon truc tarifs quand on passe par la nav
$(".nav a").click(function() {
	id_lien = $(this).attr('id');
	class_collapse = id_lien.substr(8); // on enlève 'navlink_' du début de l'ID
	$('.'+class_collapse).collapse('show');
});


// Smooth scrolling vers les anchor
$(document).ready(function() {
	$('.js-scrollTo').on('click', function() { // Au clic sur un élément
		var page = $(this).attr('href'); // Page cible
		var speed = 500; // Durée de l'animation (en ms)
		$('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
		return false;
	});
});



// Affichage de la map google map si la div existe
if (document.getElementById('carte_googlemaps')) { 

  function initialisation(){
    var optionsCarte = {
      zoom: 13,
      center: new google.maps.LatLng(46.457306, 5.714923),
      'scrollwheel': false,
      zoomControl: true,
      zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
      },
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false
    }
    var maCarte = new google.maps.Map(document.getElementById("carte_googlemaps"), optionsCarte);

    var optionsMarqueur = {
          position: maCarte.getCenter(),
          map: maCarte
      };
      var marqueur = new google.maps.Marker(optionsMarqueur);
  }
  google.maps.event.addDomListener(window, 'load', initialisation);

};
// Fin affichage google maps



// FORMULAIRE AJAX : Contact
grc_site_key = '6Lfc2ZsfAAAAAFTEEEdeTpHASVjAqKHPUOWecHAB';
$(document).ready(function() {
    // Lorsque je soumets le formulaire
    $(document).off('submit');
    $(document).on('submit', '#form_contact', function(e) {
        e.preventDefault(); // J'empêche le comportement par défaut du navigateur, c-à-d de soumettre le formulaire
        //e.stopImmediatePropagation();
 
        var $this = $(this); // L'objet jQuery du formulaire
 
        // Je récupère les valeurs
        var nom = $('#nom').val();
        var prenom = $('#prenom').val();
        var mail = $('#email').val();
        var telephone = $('#telephone').val();
        var age = $('#age').val();
        var niveau = $('#niveau').val();
        var code_postal = $('#code_postal').val();
        var ville = $('#ville').val();
        /*var objet = $('#objet').val();*/
        var message = $('#message').val();
        var newsletter = $('#form_checkbox_newsletter').prop('checked');
        var licence = $('#form_checkbox_licence').prop('checked');

        var data = $(this).serializeArray(); // convert form to array
        data.push({name: "newsletter", value: newsletter});
        data.push({name: "licence", value: licence});

        // Je vérifie une première fois pour ne pas lancer la requête HTTP
        // si je sais que mon PHP renverra une erreur
        if(nom === '' || prenom === '' || mail === '' || message === '' || telephone === '' || age === '' || code_postal === '' || ville === '') {
            var missing_nom = ''; if(nom == '') { missing_nom = ', nom'; }
            var missing_prenom = ''; if(prenom == '') { missing_prenom = ', prenom'; }
            var missing_mail = ''; if(mail == '') { missing_mail = ', mail'; }
            var missing_telephone = ''; if(telephone == '') { missing_telephone = ', telephone'; }
            var missing_age = ''; if(age == '') { missing_age = ', age'; }
            var missing_code_postal = ''; if(code_postal == '') { missing_code_postal = ', code_postal'; }
            var missing_ville = ''; if(ville == '') { missing_ville = ', ville'; }
            var missing_message = ''; if(message == '') { missing_message = ', message'; }

            var alert_string = "Les champs marqués d'une étoile (*) doivent êtres remplis, merci de remplir les champs suivants : "+
            missing_nom+missing_prenom+missing_mail+missing_telephone+missing_age+missing_code_postal+missing_ville+missing_message;

            alert(alert_string);

            /*alert("Les champs marqués d'une étoile (*) doivent êtres remplis.");*/
        } else {

          // Appel ReCaptcha et ajout de la réponse ReCaptcha aux datas
          grecaptcha.ready(function() {
            grecaptcha.execute(grc_site_key, {action: 'submit_contact_form'}).then(function(token) {

                // Une fois le token récupéré, ajout aux datas et appel ajax
                //data.push({name: 'recaptcha_token', value: token});
                data.push({name: 'recaptcha_token', value: token});

                $('#form_contact button').addClass('disabled');
                $('#form_contact button').attr("disabled","disabled");

                // Envoi de la requête HTTP en mode asynchrone
                $.ajax({
                  url: $this.attr('action'), // Le nom du fichier indiqué dans le formulaire
                  type: $this.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
                  data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
                  success: function(resultat) { // Je récupère la réponse du fichier PHP
                      console.log(resultat);
                      if(resultat == 'ok') { 
                          document.getElementById('confirmation_envoi_mail_ok').style.display = 'block';
                      } else {
                          document.getElementById('confirmation_envoi_mail_fail').style.display = 'block';
                      }
                  }
              });

            });
          });

            
        }
    });
});

// FIN FORMULAIRE AJAX : Contact

// Fermeture des lightbox de confirmation message
$(".confirmation_envoi_mail .lightbox_close, .confirmation_envoi_mail .fond_lightbox_confirm").click(function() {
  document.getElementById('confirmation_envoi_mail_ok').style.display = 'none';
  document.getElementById('confirmation_envoi_mail_fail').style.display = 'none';
});





// Gestion du bouton ReCaptcha
// function onSubmit(token) {

//   var $this = $('#form_contact'); // L'objet jQuery du formulaire
 
//   // Je récupère les valeurs
//   var nom = $('#nom').val();
//   var prenom = $('#prenom').val();
//   var mail = $('#email').val();
//   var telephone = $('#telephone').val();
//   var age = $('#age').val();
//   var niveau = $('#niveau').val();
//   var code_postal = $('#code_postal').val();
//   var ville = $('#ville').val();
//   /*var objet = $('#objet').val();*/
//   var message = $('#message').val();
//   var newsletter = $('#form_checkbox_newsletter').prop('checked');
//   var licence = $('#form_checkbox_licence').prop('checked');

//   var data = $this.serializeArray(); // convert form to array
//   data.push({name: "newsletter", value: newsletter});
//   data.push({name: "licence", value: licence});

//   // Je vérifie une première fois pour ne pas lancer la requête HTTP
//   // si je sais que mon PHP renverra une erreur
//   if(nom === '' || prenom === '' || mail === '' || message === '' || telephone === '' || age === '' || code_postal === '' || ville === '') {
//       var missing_nom = ''; if(nom == '') { missing_nom = ', nom'; }
//       var missing_prenom = ''; if(prenom == '') { missing_prenom = ', prenom'; }
//       var missing_mail = ''; if(mail == '') { missing_mail = ', mail'; }
//       var missing_telephone = ''; if(telephone == '') { missing_telephone = ', telephone'; }
//       var missing_age = ''; if(age == '') { missing_age = ', age'; }
//       var missing_code_postal = ''; if(code_postal == '') { missing_code_postal = ', code_postal'; }
//       var missing_ville = ''; if(ville == '') { missing_ville = ', ville'; }
//       var missing_message = ''; if(message == '') { missing_message = ', message'; }

//       var alert_string = "Les champs marqués d'une étoile (*) doivent êtres remplis, merci de remplir les champs suivants : "+
//       missing_nom+missing_prenom+missing_mail+missing_telephone+missing_age+missing_code_postal+missing_ville+missing_message;

//       alert(alert_string);

//       /*alert("Les champs marqués d'une étoile (*) doivent êtres remplis.");*/
//   } else {
//       // Envoi de la requête HTTP en mode asynchrone
//       $.ajax({
//           url: $this.attr('action'), // Le nom du fichier indiqué dans le formulaire
//           type: $this.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
//           data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
//           success: function(resultat) { // Je récupère la réponse du fichier PHP
//               alert(resultat);
//               if(resultat == 'ok') { 
//                   document.getElementById('confirmation_envoi_mail_ok').style.display = 'block';
//               } else {
//                   document.getElementById('confirmation_envoi_mail_fail').style.display = 'block';
//               }
//           }
//       });
//   }
// }


// Popover du footer (mentions légales)
$("[data-toggle=popover]").popover({
  html:true,
  content: function(){ return $('#legal-mentions').html()} 
});
// Fin Popover





/*$(document).ready(function(){   
    $("input[type=text]").change(function() {
           $(this).css("background","#c7c7c7");
    });
});*/

// ------------------------ TRAITEMENT DES FORMULAIRES D'HORAIRES ------------------------
$(document).ready(function() {

    var type;

    $('.form_admin_horaires button').on('click', function(e) {

        e.preventDefault(); // J'empêche le comportement par défaut du navigateur, c-à-d de soumettre le formulaire

        form = $(this).parents('form'); // Je récupère le form qui contient le button qui a été cliqué
        button = $(this);

        // Récupération des données qui nous intéressent
        type = button.attr('data-type');
        jour = button.attr('data-jour');
        from_heure = button.attr('data-from-heure');
        to_heure = button.attr('data-to-heure');

        // Détermination de l'état actuel du bouton
        if(button.hasClass('on')) { etat = 1; }
        if(button.hasClass('off')) { etat = 0; }
        
        // On met les données dans un array pour envoyer tout ça au php via l'ajax
        var data = [];
        data.push({name: "etat", value: etat});
        data.push({name: "type", value: type});
        data.push({name: "jour", value: jour});
        data.push({name: "from_heure", value: from_heure});
        data.push({name: "to_heure", value: from_heure});


        // Envoi de la requête HTTP en mode asynchrone
        $.ajax({
            url: form.attr('action'), // Le nom du fichier indiqué dans le formulaire
            type: form.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
            data: $.param(data), // Je sérialise les données
            success: function(resultat) { // Je récupère la réponse du fichier PHP

                if(resultat == 'add_ok') {
                    button.removeClass('off');
                    button.addClass('on');
                }

                else if(resultat == 'remove_ok') {
                    button.removeClass('on');
                    button.addClass('off');
                }

                else { alert('Le PHP n\'a pas renvoyé add_ok ou remove_ok'); }
            }
        });
    });
});
// ------------------------ FIN TRAITEMENT DES FORMULAIRES D'HORAIRES ------------------------



// ------------------------ TRAITEMENT DES FORMULAIRES PRESTATIONS ------------------------
/*$(document).ready(function() {

    $(document).off('submit');

    $('.form_admin_prestation').click('submit', function(e) {
        e.preventDefault(); // J'empêche le comportement par défaut du navigateur, c-à-d de soumettre le formulaire

        alert('submit détecté');

        $this = $(this); // L'objet jQuery du formulaire

        var submit_button = $("input[type=submit]:focus").val();

        alert('submit_button : '+submit_button);

        var data = $this.serializeArray(); // convert form to array
        data.push({name: "submit_button", value: submit_button});


        // Envoi de la requête HTTP en mode asynchrone
        $.ajax({
            url: $this.attr('action'), // Le nom du fichier indiqué dans le formulaire
            type: $this.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
            data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
            success: function(resultat) { // Je récupère la réponse du fichier PHP

                $('.form_admin_prestation').unbind('submit');

                nb_categorie = $("input[name='categorie']").val();
                selector_form = '#categorie'+nb_categorie+'_container .tableau_prestations';
                $(selector_form).html(resultat);

                $('.form_admin_prestation').bind('submit');

            }
        });
    });
    
});*/
// ------------------------ FIN TRAITEMENT DES FORMULAIRES PRESTATIONS ------------------------


// ------------------------ INFOS HOVER ------------------------
$('.col_update input').hover(
  function() {
    $('#admin_topbar_content').html('Cliquez sur ce bouton pour mettre à jour la prestation');
  }, function() {
    $('#admin_topbar_content').html('');
  }
);

$('.col_delete input').hover(
  function() {
    $('#admin_topbar_content').html('Cliquez sur ce bouton pour supprimer cette prestation');
  }, function() {
    $('#admin_topbar_content').html('');
  }
);

$('.col_update [name="submit_ajouter"]').hover(
  function() {
    $('#admin_topbar_content').html('Cliquez sur ce bouton pour ajouter une prestation à la liste');
  }, function() {
    $('#admin_topbar_content').html('');
  }
);
